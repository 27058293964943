<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="11"
    viewBox="0 0 16 11"
    :class="classname"
  >
    <path d="M1 2C1 0.895431 1.89543 0 3 0H10.9816C11.6214 0 12.2227 0.306168 12.599 0.823656L15.1445 4.32366C15.6545 5.02496 15.6545 5.97504 15.1445 6.67634L12.599 10.1763C12.2227 10.6938 11.6214 11 10.9816 11H3C1.89543 11 1 10.1046 1 9V2Z" />
  </svg>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
